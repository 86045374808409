export enum InventoryType {
    HOMEFARMS,
    HOMEFARM_OHNE_PFLANZEN,
    HOMEFARM_LITE,
    HOMEFARMS_PREMIUM,
    MINIFARMS,
    CAPSULES,
    CAPSULES_OHNE_FARM,
    CAPSULES_MIT_FARM,
    HERBS,
    SALADS,
    VEGETABLES,
    FLOWERS,
    MICROGREENS,
    MICROGREENS_OHNE_FARM,
    MICROGREENS_MIT_FARM,
    WEEFARMS,
    CABINETS,
    MUSHROOMS,
}

export function fromDTO(value: string): InventoryType {
    switch (value) {
        case 'HOMEFARMS':
            return InventoryType.HOMEFARMS;
        case 'HOMEFARM_OHNE_PFLANZEN':
            return InventoryType.HOMEFARM_OHNE_PFLANZEN;
        case 'HOMEFARM_LITE':
            return InventoryType.HOMEFARM_LITE;
        case 'HOMEFARMS_PREMIUM':
            return InventoryType.HOMEFARMS_PREMIUM;
        case 'MINIFARMS':
            return InventoryType.MINIFARMS;
        case 'CAPSULES':
            return InventoryType.CAPSULES;
        case 'CAPSULES_OHNE_FARM':
            return InventoryType.CAPSULES_OHNE_FARM;
        case 'CAPSULES_MIT_FARM':
            return InventoryType.CAPSULES_MIT_FARM;
        case 'HERBS':
            return InventoryType.HERBS;
        case 'SALADS':
            return InventoryType.SALADS;
        case 'VEGETABLES':
            return InventoryType.VEGETABLES;
        case 'FLOWERS':
            return InventoryType.FLOWERS;
        case 'MICROGREENS':
            return InventoryType.MICROGREENS;
        case 'MICROGREENS_OHNE_FARM':
            return InventoryType.MICROGREENS_OHNE_FARM;
        case 'MICROGREENS_MIT_FARM':
            return InventoryType.MICROGREENS_MIT_FARM;
        case 'WEEFARMS':
            return InventoryType.WEEFARMS;
        case 'CABINETS':
            return InventoryType.CABINETS;
        case 'MUSHROOMS':
            return InventoryType.MUSHROOMS;
        default:
            return null;
    }
}

const strArray = [
    'homefarms',
    'homefarm ohne Pflanzen',
    'homefarm Lite',
    'homefarm Premium',
    'minifarms',
    'Kapseln',
    'Kapseln ohne Farm',
    'Kapseln mit Farm',
    'Kräuter',
    'Salate',
    'Gemüse',
    'Blumen',
    'Microgreens',
    'Microgreens ohne Farm',
    'Microgreens mit Farm',
    'weefarms',
    'Vitrinen',
    'Pilze',
];

export function getInventoryTypeStringArray() {
    return strArray;
}

export function getInventoryTypeString(value: number) {
    if (value >= 0 && value < strArray.length) {
        return strArray[value];
    }
    return 'Keine Zuordnung: ' + value;
}
