export enum AnalysisType {
    ALL,
    HOMEFARMS,
    HOMEFARM_OHNE_PFLANZEN,
    HOMEFARM_LITE,
    HOMEFARM_PREMIUM,
    MINIFARMS,
    REORDER,
}

const strArray = [
    'Alle',
    'homefarms',
    'homefarm ohne Pflanzen',
    'homefarm Lite',
    'homefarm Premium',
    'minifarms',
    'Reorder',
];

export function getAnalysisTypeStringArray() {
    return strArray;
}

export function getAnalysisTypeString(value: number) {
    if (value >= 0 && value < strArray.length) {
        return strArray[value];
    }
    return 'Keine Zuordnung: ' + value;
}
